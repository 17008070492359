import DialogContent from '@mui/material/DialogContent';

import Dialog from '../Mui/Dialog';
import Drawer from '../Mui/Drawer';
import { isMobile } from '../../../../hooks/useBreakPoint';

const ModalWrapper = ({
  children,
  props,
}) => {
  const isMobileXsSm = isMobile();

  return (
    isMobileXsSm ? (
      <Drawer
        anchor="top"
        {...props}
      >
        {children}
      </Drawer>
    ) : (
      <Dialog
        {...props}
      >
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    )
  );
};

export default ModalWrapper;
