import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import _ from 'lodash';

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import DotsLoader from '../../../global/DotsLoader';
import FilterIcon from '../../../images/filter-icon.svg';
import CloseSvg from '../../../global/SvgComponents/Close_svg';
import TCCoinSvg from '../../../global/SvgComponents/TCCoin_svg';
import ModalWrapper from '../../../global/ModalWrapper';
import Slider from '../../../global/Mui/Slider';
import Radio from '../../../global/Mui/Radio';
import Checkbox from '../../../global/Mui/Checkbox';
import FormControlLabel from '../../../global/Mui/FormControlLabel';
import {
  DEFAULT_MAX_VALUE_OPTION,
  DEFAULT_MIN_VALUE_OPTION,
  DEFAULT_OTHER_OPTION,
  DEFAULT_TYPE_OPTION,
  FLAGS,
  OTHER_OPTIONS,
  OTHER_DEALS_OPTIONS,
  TYPE_OPTIONS,
  VALUE_OPTIONS,
} from '../utils';

import './Filter.scss';

const getFilters = ({
  minValue,
  maxValue,
  type,
  other,
}) => ({
  ...(minValue !== DEFAULT_MIN_VALUE_OPTION && { minValue }),
  ...(maxValue !== DEFAULT_MAX_VALUE_OPTION && { maxValue }),
  ...(type !== DEFAULT_TYPE_OPTION && { type }),
  ...(other !== DEFAULT_OTHER_OPTION && { other }),
});

const countFilters = (filters) => {
  let count = Object.values(filters).length;

  if (filters.minValue && filters.maxValue) {
    count -= 1;
  }

  if (filters.other) {
    count += Object.values(filters.other).length - 1;
  }

  return count;
};

const Filter = ({
  filter = {},
  onSubmit,
  onCheck,
  isCheking,
  open = false,
  onOpenChange,
  showSlider = true,
  ctaApplyText = 'Show All Rewards',
  isDeals = false,
}) => {
  const {
    minValue: initialMinValue = DEFAULT_MIN_VALUE_OPTION,
    maxValue: initialMaxValue = DEFAULT_MAX_VALUE_OPTION,
    type: initialType = DEFAULT_TYPE_OPTION,
    other: initialOther = DEFAULT_OTHER_OPTION,
  } = useMemo(() => filter, [
    filter.minValue,
    filter.maxValue,
    filter.type,
    filter.other?.[FLAGS.IS_NEW],
  ]);

  const [isOpen, setIsOpen] = useState(open);
  const [viewMinValue, setViewMinValue] = useState(initialMinValue);
  const [viewMaxValue, setViewMaxValue] = useState(initialMaxValue);
  const [minValue, setMinValue] = useState(initialMinValue);
  const [maxValue, setMaxValue] = useState(initialMaxValue);
  const [type, setType] = useState(initialType);
  const [other, setOther] = useState(initialOther);
  const [check, setCheck] = useState(true);
  const [viewCount, setViewCount] = useState(0);
  const [count, setCount] = useState(0);

  const clearFilters = useCallback(() => {
    setViewMinValue(initialMinValue);
    setViewMaxValue(initialMaxValue);
    setMinValue(initialMinValue);
    setMaxValue(initialMaxValue);
    setType(initialType);
    setOther(initialOther);
  }, [initialMinValue, initialMaxValue, initialType, initialOther]);

  const handleCheck = useCallback(async (filters) => {
    const hasResults = await onCheck(filters);

    setCheck(hasResults);
  }, [onCheck]);

  const handleOpen = useCallback(() => {
    clearFilters();
    setIsOpen(true);

    if (onOpenChange) {
      onOpenChange(true);
    }

    handleCheck(
      getFilters({
        minValue,
        maxValue,
        type,
        other,
      }),
    );
  }, [clearFilters, minValue, maxValue, type, other, handleCheck]);

  const handleClose = useCallback(() => {
    clearFilters();
    setIsOpen(false);

    if (onOpenChange) {
      onOpenChange(false);
    }
  }, [clearFilters]);

  const handleViewValueChange = useCallback((_event, newValue) => {
    const [newViewMinValue, newViewMaxValue] = newValue;

    setViewMinValue(String(newViewMinValue));
    setViewMaxValue(String(newViewMaxValue));
  }, []);

  const handleValueChange = useCallback((_event, newValue) => {
    const [newMinValue, newMaxValue] = newValue;

    setViewMinValue(String(newMinValue));
    setViewMaxValue(String(newMaxValue));
    setMinValue(String(newMinValue));
    setMaxValue(String(newMaxValue));
  }, []);

  const handleTypeChange = useCallback((event) => {
    setType(event.target.value);
  }, []);

  const handleOtherChange = useCallback((event) => {
    setOther(prev => {
      const newOther = { ...prev };

      if (event.target.checked) {
        newOther[event.target.value] = true;
      } else {
        delete newOther[event.target.value];
      }

      return newOther;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    const filters = getFilters({
      minValue,
      maxValue,
      type,
      other,
    });

    if (!_.isEqual(filter, filters)) {
      onSubmit(filters);
    }

    const currentCount = countFilters(filters);

    setCount(currentCount);
    setViewCount(currentCount);
    setIsOpen(false);

    if (onOpenChange) {
      onOpenChange(false);
    }
  }, [minValue, maxValue, type, other, filter]);

  const handleClear = useCallback(() => {
    setViewMinValue(DEFAULT_MIN_VALUE_OPTION);
    setViewMaxValue(DEFAULT_MAX_VALUE_OPTION);
    setMinValue(DEFAULT_MIN_VALUE_OPTION);
    setMaxValue(DEFAULT_MAX_VALUE_OPTION);
    setType(DEFAULT_TYPE_OPTION);
    setOther(DEFAULT_OTHER_OPTION);
  }, []);

  useEffect(() => {
    const filters = getFilters({
      minValue,
      maxValue,
      type,
      other,
    });

    handleCheck(getFilters(filters));

    setViewCount(countFilters(filters));
  }, [minValue, maxValue, type, other, handleCheck]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    const filters = getFilters({
      minValue: initialMinValue,
      maxValue: initialMaxValue,
      type: initialType,
      other: initialOther,
    });

    const currentCount = countFilters(filters);

    setCount(currentCount);
    setViewCount(currentCount);
  }, []);

  return (
    <div className="Filter">
      <ModalWrapper
        props={{
          open: isOpen,
          onClose: handleClose,
        }}
      >
        <Box className="Filter__modal">
          <div className="Filter__modal--title">
            <div className="Filter__modal--title--text">Filter by</div>
            <div onClick={handleClose} aria-hidden className="Filter__modal--title--close ">
              <CloseSvg stroke="#A9A9A9" />
            </div>
          </div>
          { showSlider && (
          <div className="Filter__modal--filters">
            <div className="Filter__modal--filters-title">
              TrashieCash
              <TCCoinSvg width="14" height="14" color="var(--trashie-primary-code-blue)" />
            </div>
            <Slider
              value={[viewMinValue, viewMaxValue]}
              onChange={handleViewValueChange}
              onChangeCommitted={handleValueChange}
              getAriaValueText={val => VALUE_OPTIONS.find((option) => option.value === val)?.label ?? ''}
              step={null}
              marks={VALUE_OPTIONS}
              min={VALUE_OPTIONS[0].value}
              max={VALUE_OPTIONS[VALUE_OPTIONS.length - 1].value}
            />
          </div>
          )}
          <div className="Filter__modal--filters">
            <div className="Filter__modal--filters-title">Value Type</div>
            {TYPE_OPTIONS.map((option) => (
              <FormControlLabel
                label={option.label}
                control={(
                  <Radio
                    key={option.value}
                    id={option.value}
                    checked={type === option.value}
                    onChange={handleTypeChange}
                    value={option.value}
                    name="type-filter"
                    inputProps={{ 'aria-label': option.label }}
                  />
                )}
              />
            ))}
          </div>
          <div className="Filter__modal--filters">
            <div className="Filter__modal--filters-title">Other filters</div>
            {(isDeals ? OTHER_DEALS_OPTIONS : OTHER_OPTIONS).map((option) => (
              <FormControlLabel
                label={option.label}
                control={(
                  <Checkbox
                    value={option.value}
                    checked={!!other[option.value]}
                    onChange={handleOtherChange}
                    inputProps={{ 'aria-label': option.label }}
                  />
                )}
              />
            ))}
          </div>
          <div className="Filter__modal--buttons">
            {!isCheking && !check ? (
              <div className="Filter__modal--buttons-label">
                No rewards match this filter criteria
              </div>
            ) : (
              <Button
                size={BUTTON_SIZE.LARGE}
                type={BUTTON_TYPE.QUATERNARY}
                onClick={handleSubmit}
                disabled={isCheking}
              >
                {isCheking ? (
                  <DotsLoader />
                ) : (
                  (viewCount > 0 && 'Apply Filters') || ctaApplyText
                )}
              </Button>
            )}
            <Button
              size={BUTTON_SIZE.LARGE}
              type={BUTTON_TYPE.LINK_QUATERNARY}
              onClick={handleClear}
              disabled={viewCount === 0}
            >
              Clear Filters
            </Button>
          </div>
        </Box>
      </ModalWrapper>
      <Button
        size={BUTTON_SIZE.LARGE}
        type={BUTTON_TYPE.SECONDARY}
        className="Filter__button"
        onClick={handleOpen}
      >
        <img className="Filter__button--icon" src={FilterIcon} alt="filter icon" />
        <div className="Filter__button--label">Filter</div>
        {!!count && (<div className="Filter__button--count">{count}</div>)}
      </Button>
    </div>
  );
};

export default Filter;
