import { useEffect, useMemo } from 'react';

import useExternalScript from '../../../../../hooks/useExternalScript';

const ELEMENT_ID = 'FeedSnippet';

const RewardFeed = ({
  rewardItem,
}) => {
  const {
    store: {
      title,
      productFeedURL,
    },
  } = useMemo(() => (
    rewardItem
  ), [rewardItem]);

  useExternalScript(productFeedURL, true, ELEMENT_ID);

  useEffect(() => (
    () => {
      // affiliate.com snippet cleanup
      window.__affiliateComSnippetLoaded = {};
    }
  ), []);

  if (!productFeedURL) {
    return null;
  }

  return (
    <div id="RewardFeed" className="feed">
      <p className="title">{`PICKS FROM ${title}`}</p>
      <div id="FeedSnippet" className="snippet" />
    </div>
  );
};

export default RewardFeed;
