import { useMemo, useCallback } from 'react';
import { Input } from '@mui/material';
import searchIcon from '../../../images/search-icon.svg';
import { isMobile } from '../../../../../hooks/useBreakPoint';
import './RewardsSearchInput.scss';

const RewardsSearchInput = ({
  setIsSearching,
  clearFiltersAndResults,
  isDeals,
}) => {
  const isMobileValue = isMobile();
  const placeHolderText = useMemo(() => {
    if (!isMobileValue) {
      return isDeals ? 'Search deals' : 'Search rewards';
    }
    return 'Search';
  }, [isDeals]);

  const onOpenModal = useCallback(() => {
    setIsSearching();
    clearFiltersAndResults();
  }, []);

  return (
    <div className="RewardsSearchInput__input-container">
      <Input
        className="RewardsSearchInput__input"
        placeholder={placeHolderText}
        onClick={onOpenModal}
        onChange={onOpenModal}
        disableUnderline
      />
      <img
        className="RewardsSearchInput__search-icon"
        src={searchIcon}
        alt="search-icon"
      />
    </div>

  );
};

export default RewardsSearchInput;
