import { useEffect } from 'react';

import { isTrashieApp } from '../config/config';

const useExternalScript = (url, async, elementId) => {
  useEffect(() => {
    let parent;
    let script;

    if (url && isTrashieApp()) {
      script = document.createElement('script');
      script.setAttribute('src', url);
      script.async = Boolean(async);
      script.onload = () => console.log('External script loaded', url);

      parent = (elementId && document.getElementById(elementId)) ?? document.querySelector('head');
      parent.appendChild(script);
    }

    return () => {
      if (url && isTrashieApp()) {
        console.log('External script removed', url);
        parent.removeChild(script);
      }
    };
  }, [url]);
};

export default useExternalScript;
