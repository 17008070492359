import { useCallback, useState } from 'react';
import { FormCheck, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../../config/externalURL';
import {
  getActionType,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  TRACKER_IDS,
} from '../../../config/tracker';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import GiftCardCover from './GiftCardCover';
import { REWARD_AMOUNT_TYPES, REWARD_AMOUNT_TYPES_ID } from '../../../config/rewards';

import './GiftCardContent.scss';

const {
  giftCards: {
    brandPage: {
      confirmButton: trackerConfirmButtonGiftCard,
    },
  },
} = TRACKER_IDS;

const GiftCardContentConfirm = ({
  giftCardBrandItem,
  giftCard,
  onSubmit,
  onClose,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);

  const handleOnSubmit = useCallback(() => {
    onSubmit?.();
  }, [onSubmit]);

  return (
    <div className="GiftCardContentConfirm">
      <GiftCardCover
        logoUrl={giftCardBrandItem.giftCardUrl}
        value={REWARD_AMOUNT_TYPES[REWARD_AMOUNT_TYPES_ID.VALUE]?.getText({
          rewardAmount: giftCard.amount,
          type: giftCard.type,
        })}
      />
      <div className="GiftCardContentConfirm__title">
        ALL GOOD?
      </div>
      <FormCheck
        className="GiftCardContentConfirm__terms"
        onChange={() => setIsButtonEnabled(!isButtonEnabled)}
        type="checkbox"
        id="gift-card-confirm-button"
        label={(
          <span>
            Yes, and I agree to the
            {' '}
            <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            {' and '}
            <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener noreferrer">
              Terms of Service.
            </a>
          </span>
        )}
      />
      <OverlayTrigger
        placement="top"
        overlay={isButtonEnabled ? (
          <Tooltip>
            Please tick the checkbox to confirm
          </Tooltip>
        ) : <div />}
      >
        <Button
          disabled={isButtonEnabled}
          type={BUTTON_TYPE.PRIMARY}
          size={BUTTON_SIZE.LARGE}
          onClick={handleOnSubmit}
          trackerProps={{
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]:
              getTrackerId(trackerConfirmButtonGiftCard),
            [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]:
              getActionType(trackerConfirmButtonGiftCard),
            [POSTHOG_CAPTURE_ATTRIBUTES.GIFT_CARD_ID]: giftCardBrandItem.uid,
            [POSTHOG_CAPTURE_ATTRIBUTES.GIFT_CARD_NAME]: giftCardBrandItem.name,
          }}
        >
          CONTINUE
        </Button>
      </OverlayTrigger>
      <Button
        type={BUTTON_TYPE.SECONDARY}
        size={BUTTON_SIZE.LARGE}
        onClick={() => onClose()}
      >
        GO BACK
      </Button>
    </div>
  );
};

export default GiftCardContentConfirm;
