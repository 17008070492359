import { useMemo } from 'react';
import classNames from 'classnames';

import TBBBlueRegister from '../../../../images/tbb-blue-register.png';
import TrashiCoinLogo from '../../../../images/trashie-logo.webp';
import NewTrashieLogo from '../../../../images/new-trashie-logo.svg';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import {
  getNextButtonStyles,
  isTechRecyclingProductType,
  isTextilRecyclingProductType,
} from '../../../../utils/tbbRegistration';

import './Welcome.scss';

const Welcome = ({
  handleOnClick,
  storeConfig,
  recyclingProductType,
  stepTrackerProps,
}) => {
  const {
    isTrashie: isTrashieStore,
    mainLogoSrc,
    registerTbbFlow,
  } = useMemo(() => storeConfig, [storeConfig]);

  const isTextilRecycling = isTextilRecyclingProductType(recyclingProductType);

  const isTrashie = useMemo(() => (
    isTrashieStore || !mainLogoSrc
  ), [isTrashieStore, mainLogoSrc]);

  const storeLogo = useMemo(
    () => {
      if (isTrashie && isTextilRecycling) {
        return TrashiCoinLogo;
      }

      if (isTrashie && isTextilRecycling) {
        return NewTrashieLogo;
      }
      return mainLogoSrc;
    },
    [isTrashie, mainLogoSrc, isTextilRecycling],
  );

  return (
    <div className="RegisterWelcome">
      {isTrashie && isTextilRecycling && (
        <div className="RegisterWelcome__image">
          <img
            src={TBBBlueRegister}
            alt="The take back bag"
          />
        </div>
      )}
      <div className={classNames('RegisterWelcome__logo', { 'is-trashie': isTrashie })}>
        <img
          src={storeLogo}
          alt="store logo"
          style={registerTbbFlow?.landingPageStep?.logoStyleProperties}
        />
      </div>
      <div className="RegisterWelcome__text">
        {registerTbbFlow?.landingPageStep?.description || (
          isTechRecyclingProductType(recyclingProductType) ? (
            <>
              REGISTER YOUR Tech TAKE
              <br />
              BACK Box AND GET REWARDS
            </>
          ) : (
            <>
              REGISTER YOUR TAKE BACK BAG
              <br />
              AND GET REWARDS
            </>
          )
        )}
      </div>
      <Button
        type={BUTTON_TYPE.QUATERNARY}
        size={BUTTON_SIZE.LARGE}
        onClick={handleOnClick}
        style={getNextButtonStyles(storeConfig)}
        trackerProps={stepTrackerProps}
      >
        {registerTbbFlow?.landingPageStep?.button?.text ?? 'GET STARTED'}
      </Button>
    </div>
  );
};

export default Welcome;
